import { disabledColor } from "theme";
import { IconProps } from "types/IconProps";

type CheckboxProps = IconProps & {
  disabled: boolean;
};

export function Checked(props: CheckboxProps) {
  const color = props.disabled ? disabledColor : "#01c1be";

  return (
    <svg
      id="Komponente_197_14"
      data-name="Komponente 197 – 14"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? "26px"}
      height={props.height ?? "26px"}
      viewBox="0 0 26 26"
    >
      <g
        id="Rechteck_9779"
        data-name="Rechteck 9779"
        fill={color}
        stroke={color}
        strokeWidth="2"
      >
        <rect width="26" height="26" rx="8" stroke="none" />
        <rect x="1" y="1" width="24" height="24" rx="7" fill="none" />
      </g>
      <path
        id="Pfad_5489"
        data-name="Pfad 5489"
        d="M9.114,16.468a.873.873,0,0,1-.637-.279L4.236,11.676a.874.874,0,1,1,1.274-1.2l3.6,3.831,7.34-8.03a.873.873,0,1,1,1.292,1.17L9.76,16.18a.873.873,0,0,1-.637.288Z"
        transform="translate(2.001 1.789)"
        fill="#fff"
      />
    </svg>
  );
}

export function Unchecked(props: CheckboxProps) {
  const color = props.disabled ? disabledColor : "#01c1be";

  return (
    <svg
      id="Komponente_197_14"
      data-name="Komponente 197 – 14"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? "26px"}
      height={props.height ?? "26px"}
      viewBox="0 0 26 26"
    >
      <g
        id="Rechteck_9779"
        data-name="Rechteck 9779"
        fill="#fff"
        stroke={color}
        strokeWidth="2"
      >
        <rect width="26" height="26" rx="8" stroke="none" />
        <rect x="1" y="1" width="24" height="24" rx="7" fill="none" />
      </g>
    </svg>
  );
}

export function Inactive(props: CheckboxProps) {
  return (
    <svg
      id="Komponente_197_14"
      data-name="Komponente 197 – 14"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? "26px"}
      height={props.height ?? "26px"}
      viewBox="0 0 26 26"
    >
      <title>Meldung kann nicht nach MediaAccount exportiert werden</title>
      <g
        id="Rechteck_9779"
        data-name="Rechteck 9779"
        fill="#fff"
        stroke={disabledColor}
        strokeWidth="2"
      >
        <rect width="26" height="26" rx="8" stroke="none" />
        <rect x="1" y="1" width="24" height="24" rx="7" fill="none" />
        <line
          x1="6"
          y1="6"
          x2="20"
          y2="20"
          stroke={disabledColor}
          strokeWidth="2"
        />
        <line
          x1="6"
          y1="20"
          x2="20"
          y2="6"
          stroke={disabledColor}
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export function Hover(props: CheckboxProps) {
  const color = props.disabled ? disabledColor : "#01c1be";

  return (
    <svg
      id="Komponente_197_14"
      data-name="Komponente 197 – 14"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? "26px"}
      height={props.height ?? "26px"}
      viewBox="0 0 26 26"
    >
      <g
        id="Rechteck_9779"
        data-name="Rechteck 9779"
        fill="#fff"
        stroke={color}
        strokeWidth="2"
      >
        <rect width="26" height="26" rx="8" stroke="none" />
        <rect x="1" y="1" width="24" height="24" rx="7" fill="none" />
      </g>
      <path
        id="Pfad_5489"
        data-name="Pfad 5489"
        d="M9.114,16.468a.873.873,0,0,1-.637-.279L4.236,11.676a.874.874,0,1,1,1.274-1.2l3.6,3.831,7.34-8.03a.873.873,0,1,1,1.292,1.17L9.76,16.18a.873.873,0,0,1-.637.288Z"
        transform="translate(2.001 1.789)"
        fill="#bebebe"
      />
    </svg>
  );
}
