import { makeStyles, Paper, Typography, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { MeldungView } from "types/MeldungView";
import Checkbox from "components/Checkbox";
import { getMeldungsDetailUrl } from "lib/urls";

type Props = {
  meldung: MeldungView;
  query: string;
  selected: boolean;
  exported: boolean;
  onToggle: () => void;
  loadingToggle: boolean;
  exportDisabled: boolean;
};

type StyleProps = {
  exported: boolean;
};

export default function Kachel(props: Props) {
  const { meldung, exported, exportDisabled } = props;
  const classes = useStyles({ exported });
  const history = useHistory();

  return (
    <Paper className={classes.paperStyle} elevation={3}>
      <Grid className={classes.grid} container alignItems={"flex-start"}>
        <Grid
          item
          xs={meldung.representantBildUrl ? 8 : 12}
          className={
            meldung.representantBildUrl
              ? classes.kachelRightWithImage
              : classes.kachelRight
          }
        >
          <div className={classes.kachelHeaderContainer}>
            <Checkbox
              loading={props.loadingToggle}
              checked={props.selected || exported}
              inactive={exportDisabled}
              onToggle={props.onToggle}
              className={classes.kachelSelectionCheckbox}
              disabled={exported || exportDisabled ? true : false}
            />
            <div
              className={classes.kachelInformationContainer}
              onClick={() =>
                history.push(getMeldungsDetailUrl(meldung.meldungDetailUrl))
              }
            >
              <Typography
                variant="caption"
                display="block"
                className={classes.kachelCaption}
              >
                {meldung.veroeffentlicht} | {meldung.herkunftsland.name}
              </Typography>
              <Typography variant="caption" display="block">
                <span className={classes.quelleStyle}>
                  {meldung.quelle?.quelleTitel}
                </span>
              </Typography>
            </div>
          </div>
          <div
            onClick={() =>
              history.push(getMeldungsDetailUrl(meldung.meldungDetailUrl))
            }
          >
            <Typography
              variant="subtitle1"
              className={classes.headlineStyle}
              dangerouslySetInnerHTML={{
                __html: meldung.previewHeadlineHtml,
              }}
            ></Typography>
            <Typography
              variant="caption"
              className={classes.descriptionStyle}
              display="block"
              dangerouslySetInnerHTML={{
                __html: meldung.previewTextHtml,
              }}
            ></Typography>
          </div>
        </Grid>

        {meldung.representantBildUrl && (
          <Grid
            item
            xs={4}
            onClick={() =>
              history.push(getMeldungsDetailUrl(meldung.meldungDetailUrl))
            }
          >
            <img
              alt={"Error Img"}
              className={classes.representantImageStyle}
              src={meldung.representantBildUrl}
            ></img>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    zIndex: 99,
    overflow: "hidden",
    minWidth: "20em",
    height: 250,
    backgroundColor: "white",
    borderRadius: 10,
    cursor: "pointer",
    padding: 10,
    boxShadow:
      "rgba(9, 32, 77, 0.12) 0px 3px 8px, rgba(29, 17, 51, 0.12) 0px 0px 2px",
    borderRight: (props: StyleProps) =>
      props.exported ? "7px solid rgba(1, 193, 190, 0.5)" : "",
  },
  kachelCaption: {
    fontSize: "0.75rem",
    lineHeight: "1.1",
  },
  kachelHeaderContainer: {
    display: "flex",
  },
  kachelInformationContainer: {
    color: "grey",
    display: "flex",
    flexDirection: "column",
  },
  kachelSelectionCheckbox: {
    alignSelf: "start",
    width: "2.3rem",
    height: "2.3rem",
    color: "#00c1be",
  },
  grid: {
    flexGrow: 1,
  },
  kachelRightWithImage: {
    borderRight: "1px solid #dadada",
    flexGrow: 1,
    height: 220, //TODO: Nicht fixed,
    paddingRight: 3,
    marginTop: "5px",
  },
  kachelRight: {},
  headlineStyle: {
    color: theme.textHeadlineColor,
    marginTop: 5,
    fontWeight: "bold",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& .mark": theme.textHighlight,
  },
  descriptionStyle: {
    marginTop: 8,
    "& .mark": theme.textHighlight,
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: theme.textColor,
  },
  representantImageStyle: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 5,
  },
  quelleStyle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
